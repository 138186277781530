import { useMemo } from 'react';
import {
  useQuery,
  UseQueryResult,
  UseQueryOptions,
  QueryKey,
  QueryFunction,
} from '@tanstack/react-query';

const useQueryWithLoadTime = <T,>(
  queryKey: QueryKey,
  queryFn: QueryFunction<T>,
  options?: Omit<UseQueryOptions<T>, 'onSuccess'> & {
    onSuccess?: (data: T, loadTime: string) => void;
  },
): UseQueryResult<T> => {
  // eslint-disable-next-line react-hooks/exhaustive-deps
  const startTime = useMemo(() => performance.now(), [options?.enabled]);

  const result = useQuery({
    queryKey,
    queryFn,
    ...options,
    // eslint-disable-next-line @tanstack/query/no-deprecated-options
    onSuccess: options?.onSuccess
      ? (data) => {
          const loadTime = ((performance.now() - startTime) / 1000).toFixed(2);

          options.onSuccess?.(data, loadTime);
        }
      : undefined,
  });

  return result;
};

export default useQueryWithLoadTime;
