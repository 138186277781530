import { css } from '@emotion/react';
import styled from '@emotion/styled';
import useTranslation from 'next-translate/useTranslation';
import Box from '@ui-v2/core/Box/Box';
import Button, { StyledIconWrapper } from '@ui-v2/core/Button/Button';
import Text from '@ui-v2/core/Text/Text';
import { createCSSFilterFromHex } from '@ui-v2/utils/styleUtils';

export interface Props {
  canDecrement?: boolean;
  canIncrement?: boolean;
  decrement: () => void;
  id?: string;
  increment: () => void;
  value: number;
}

const StyledButton = styled(Button)(
  ({ theme: { colours } }) => css`
    width: 32px;
    height: 32px;

    &:disabled {
      border: 1px solid ${colours.border.default};
      background: ${colours.surface.disabled};

      ${StyledIconWrapper} > div > svg {
        ${createCSSFilterFromHex(colours.icons.disabled)};
      }
    }
  `,
);

const Counter = ({
  canDecrement,
  canIncrement,
  decrement,
  id,
  increment,
  value,
}: Props) => {
  const { t } = useTranslation();

  return (
    <Box alignItems="center" display="flex" gap={12}>
      <StyledButton
        aria-label={t('decrement')}
        disabled={!canDecrement}
        icon="minusIcon"
        id={`${id}Decrement`}
        isRounded
        onClick={decrement}
        size="iconSmall"
        type="button"
        variant="secondary"
      />
      <Box textAlign="center" widthCustom={18}>
        <Text as="div" variant="heading-4">
          {value}
        </Text>
      </Box>
      <StyledButton
        aria-label={t('increment')}
        disabled={!canIncrement}
        icon="plusIcon"
        id={`${id}Increment`}
        isRounded
        onClick={increment}
        size="iconSmall"
        type="button"
        variant="secondary"
      />
    </Box>
  );
};

export default Counter;
